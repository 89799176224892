import React from "react";
import Head from "../../components/Head.js";
import Layout from "../../components/Layout.js";
import RightSidebar from "../../components/RightSidebar.js";

import RecommendTop from "../../components/RecommendTop.js";
import RecommendOther from "../../components/RecommendOther.js";

const allRecommendations = require("../recommendations.json");

// mark
const recommendPlaceInfo = allRecommendations[1];

const TestPage = () => {
  return (
    <>
      <Head
        title={`Austin City Tacos Recommends: ` + recommendPlaceInfo.title}
        description={`One of our top recommendations is ${recommendPlaceInfo.title}`}
      />
      <Layout>
        <main>
          <div className="main-wrapper main-wrapper-one-sidebar">
            <div className="primary-wrapper">
              <h1>Austin City Tacos Recommends: {recommendPlaceInfo.title}</h1>

              <RecommendTop data={recommendPlaceInfo}></RecommendTop>

              <div className="big-description">
                <p>
                  It's honestly hard to beat Veracruz All Natural for so many
                  reason. We typically don't like standing in line for anything,
                  but some things are worth the wait. The Migas tacos are the
                  obvious heavy hitters, but the doña reyna will send you to the
                  next dimension. They offer a range of smoothies and juices
                  that pair nicely with the distict flavors. A "pink panther"
                  smoothie and barbacoa taco on a summer evening, please...
                  and... thank you.
                </p>
                <p>
                  Veracruz All Natural is owned by Reyna and Maritza Vazquez,
                  who are sisters who were born and raised in Mexico and now
                  live in Austin. There are now five locations in Austin and
                  Round Rock and their menu has been recognized nationally for
                  quality and taste. Be sure to check out their online store at{" "}
                  <a
                    href="https://store.veracruzallnatural.com"
                    target="_blank"
                  >
                    https://store.veracruzallnatural.com
                  </a>{" "}
                  for a fun assortment of hats and shirts.
                </p>
              </div>
            </div>

            <div className="right-sidebar">
              <RecommendOther title={recommendPlaceInfo.title}></RecommendOther>
              <RightSidebar></RightSidebar>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default TestPage;
